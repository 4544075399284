import React from 'react';
import './App.css';
import { Image, Button, Icon } from 'semantic-ui-react'
import HomepageHeader from './HomepageHeader';
import ContentPane from './ContentPane';
import { HOMEPAGE } from './homepage/constants';


const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

const mailOnClick = () => {
  window.location.href = 'mailto:evanblasy@gmail.com';
}

const linkedInOnClick = () => {
  window.open('https://www.linkedin.com/in/evan-blasy/', "_blank");
}

const githubOnClick = () => {
  window.open('https://github.com/evanblasy', "_blank");
}

function App() {
  const experience_ref = React.useRef(null);
  const background_ref = React.useRef(null);
  const contact_ref = React.useRef(null);
  const education_ref = React.useRef(null);

  const experience_scroll = () => scrollToRef(experience_ref);
  const background_scroll = () => scrollToRef(background_ref);
  const contact_scroll = () => scrollToRef(contact_ref);
  const education_scroll = () => scrollToRef(education_ref);

  return (
    <div className='App'>
      <HomepageHeader
        experience={experience_scroll}
        background={background_scroll}
        contact={contact_scroll}
        education={education_scroll}
      />
      <Image centered circular size='small' src='/me.jpg' style={{ marginTop: '15px' }} />
      <div className='content-pane' ref={contact_ref}>
        <div className=' contact'>
          <Button color='black' onClick={githubOnClick}>
            <Icon name='github' /> Github
        </Button>
        <Button color='linkedin' onClick={linkedInOnClick}>
            <Icon name='linkedin' /> LinkedIn
        </Button>
          <Button color='mail' onClick={mailOnClick}>
            <Icon name='mail' /> Mail
        </Button>
        </div>
      </div>
      <ContentPane
        givenRef={experience_ref}
        title={HOMEPAGE.EXPERIENCE.title}
        content={HOMEPAGE.EXPERIENCE.content}
      />
      <ContentPane
        givenRef={background_ref}
        title={HOMEPAGE.BACKGROUND.title}
        content={HOMEPAGE.BACKGROUND.content}
      />
      <ContentPane
        givenRef={education_ref}
        title={HOMEPAGE.EDUCATION.title}
        content={HOMEPAGE.EDUCATION.content}
      />
    </div>
  );
}

export default App;
