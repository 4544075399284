import React from 'react';

export const HOMEPAGE = {
    EXPERIENCE: {
        title: 'Experience',
        content: ([
            <p className='text'>
                This past summer, I was a software engineer intern on a team of 10 engineers at <a target="_blank" href="https://www.facebook.com" rel="noopener noreferrer">Facebook</a>. I interfaced with the team manager and peer managers on a daily
                basis. I was assigned as the sole intern on a project to complete objectives assigned for the summer. I presented findings at the project completion which demonstrated real action on bad actors on the platform.
                I utilized multiple code bases and adapted for each assignment with the appropriate solutions. I cross collaborated with multiple teams to ensure the project considered multiple inputs.
            </p>,
            <p className='text'>
                I was a Full-Stack software engineer at <a target="_blank" href="https://www.lucasys.com" rel="noopener noreferrer"> Lucasys Inc.</a> I was responsible for the development of one of the critical modules. I developed the front end
                with React as well as React-Redux, while building a MongoDB database that was served by a Java server. I was personally tasked with developing and building the critical Copilot Module, which drives Deferred Tax data processing.
                I regularly interfaced with the CEO and CTO about my goals and targets.
            </p>,
            <p className='text'>
                Previously, I worked for the <a target="_blank" href="https://medicine.yale.edu" rel="noopener noreferrer">Yale School of Medicine</a> in the department of Biostatistics. I worked with the team that maintained the
                <a target="_blank" href="https://neuron.yale.edu/neuron/" rel="noopener noreferrer"> NEURON simulator</a>, which is used in laboratories and classrooms around the world for building and using computational models of neurons.
                I was tasked with creating a module to export the NEURON datatype into the more general and widely used <a target="_blank" href="https://en.wikipedia.org/wiki/SBML" rel="noopener noreferrer">SBML</a> format.
                Using Python 3, I developed the export capabilities of the system to allow NEURON simulations to be run on different 3rd party SBML simulators.
            </p>
        ])
    },
    BACKGROUND: {
        title: 'Background',
        content: (
            <p className='text'>
                I was born in Midland, Michigan, but I spent most of my life growing up in Savannah, Georgia. I currently study Computer Science
                at Yale University in New Haven, Connecticut.
            </p>
        )
    },
    EDUCATION: {
        title: 'Education',
        content: ([
            (<p className='text'>
                In August of 2017, I enrolled in Yale University. I am now a Computer Science major in my third year. I
                have taken all of the core classes required
                by the computer science major at my school, including Algorithms and Computer Architecture. For the
                major, 6 electives must be taken. I have chosen as
                part of my major a Deep Learning Class and a Backend Compilers class.
            </p>),
            (< p className='text'>
            I take part in many extra curricular activities, as listed below.
            <ul className='extra-curricular'>
                <li><a target="_blank" href="https://codehavenyale.com" rel="noopener noreferrer">Code Haven</a>. Taught classes of students
                    around the City of New Haven to introduce them to the specifics of computer
                    science.
                </li>
                <li><a target="_blank" href="https://yaledailynews.com" rel="noopener noreferrer">Yale Daily News</a> Tech Team. Helped
                    established news outlet enter into the
                    21st century by focusing on allowing more data driven articles.
                </li>
                <li><a target="_blank" href="https://yaleootb.com" rel="noopener noreferrer">Yale Out of the Blue</a>. Auditioned Contemporary A
                    Capella Group. Head Business Manager 2018-2019.
                </li>

            </ul>
            </p>)
        ])
    }
}
