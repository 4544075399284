import React from 'react';
import { Header } from 'semantic-ui-react';

const ContentPane = (props) => {
    const {title, content, givenRef } = props;
    return (
        <div className='content-pane' id={title.toLowerCase()} ref={givenRef}>
            <Header style={{fontSize: '2rem',}}> {title} </Header>
            {content}
        </div>
    );
};

export default ContentPane;
