import React from 'react'
import {Button } from 'semantic-ui-react'

const HomepageHeader = (props) => {

    const {experience, background, contact, education } = props;



    return (
        <div className='page-header'>
            <div className='inner-page-header'>
                <div className='name'>Evan Blasy</div>

                <Button.Group >
                    <Button
                        content='Experience'
                        onClick={experience}
                    />
                    <Button
                        content='Background'
                        onClick={background}
                    />
                    <Button
                        content='Education'
                        onClick={education}
                    />
                    <Button
                        content='Contact'
                        onClick={contact}
                    />
                </Button.Group>
            </div>
        </div>
    );
};

export default HomepageHeader
